var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-sidebar',{attrs:{"id":"add-external-link-account","body-class":"custom-scroll","aria-labelledby":"Add/Edit external link account","aria-label":"Add/Edit external link account","shadow":"","bg-variant":"white","backdrop-variant":`dark`,"no-close-on-esc":"","no-close-on-backdrop":"","lazy":"","backdrop":"","right":"","width":"70vw"},on:{"hidden":_vm.sideBarHidden},scopedSlots:_vm._u([{key:"header",fn:function({ hide }){return [_c('h3',{staticClass:"font-21 theme-font-medium mb-0"},[_vm._v(" Add new external link account ")]),_c('div',{staticClass:"sidebar-header-close"},[_c('a',{attrs:{"href":"javascript:void(0)","aria-labelledby":"Add external link account title"},on:{"click":function($event){hide;
          _vm.$emit('closeSideBar');}}},[_c('img',{attrs:{"src":require("../../assets/img/close.png"),"alt":"close-tab"}})])])]}},{key:"default",fn:function({}){return [(_vm.showForm)?_c('b-form',{attrs:{"id":"external-link-account-form","name":"external-link-account-form","autocomplete":"off","novalidate":""}},[_c('b-form-row',[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('b-form-select',{staticClass:"form-control",class:{
                  'is-value-exist': _vm.authenticationMethod != null,
                },attrs:{"id":"authentication-method"},on:{"input":function($event){_vm.authenticationMethod = $event}},model:{value:(_vm.authenticationMethod),callback:function ($$v) {_vm.authenticationMethod=$$v},expression:"authenticationMethod"}},[_c('b-form-select-option',{attrs:{"value":"azureB2C"}},[_vm._v("Azure B2C")])],1),_c('label',{attrs:{"for":"authentication-method"}},[_vm._v("Authentication method")])],1)])],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                  'is-invalid':
                    !_vm.externalLinkAccountDetails.accountName && _vm.formSubmitted,
                },attrs:{"id":"external-link-account-name","type":"text","placeholder":" ","required":""},model:{value:(_vm.externalLinkAccountDetails.accountName),callback:function ($$v) {_vm.$set(_vm.externalLinkAccountDetails, "accountName", $$v)},expression:"externalLinkAccountDetails.accountName"}}),_c('label',{attrs:{"for":"external-link-account-name"}},[_vm._v("External link account name")]),(
                  !_vm.externalLinkAccountDetails.accountName && _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Link account name required.")]):_vm._e()],1)])],1),_c('b-col',{staticClass:"pt-md-2 mt-md-1",attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"property-dependent","name":"property-dependent","disabled":_vm.isAddressSetupDone},model:{value:(_vm.externalLinkAccountDetails.isForEachProperty),callback:function ($$v) {_vm.$set(_vm.externalLinkAccountDetails, "isForEachProperty", $$v)},expression:"externalLinkAccountDetails.isForEachProperty"}},[_vm._v(" Property dependent ")])],1)],1)],1),(_vm.isAddressSetupDone)?_c('b-form-row',[_c('b-col',[_c('h6',{staticClass:"font-14"},[_c('InfoCircelYellow',{staticClass:"mr-1 mt-n1"}),_vm._v(" "+_vm._s(_vm.DISPLAY_MESSAGES.ADDRESS_SETUP_NOT_DONE_LINK_ACCOUNT_ERROR)+" ")],1)])],1):_vm._e(),_c('b-form-row',[_c('b-col',{attrs:{"sm":"12","lg":"10"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                  'is-invalid':
                    (!_vm.externalLinkAccountDetails.tenantAuthCodeEndPoint &&
                      _vm.formSubmitted) ||
                    (!_vm.validateUrl(
                      _vm.externalLinkAccountDetails.tenantAuthCodeEndPoint
                    ) &&
                      _vm.formSubmitted),
                },attrs:{"id":"auth-code-end-point","type":"text","placeholder":" ","required":""},model:{value:(_vm.externalLinkAccountDetails.tenantAuthCodeEndPoint),callback:function ($$v) {_vm.$set(_vm.externalLinkAccountDetails, "tenantAuthCodeEndPoint", $$v)},expression:"externalLinkAccountDetails.tenantAuthCodeEndPoint"}}),_c('label',{attrs:{"for":"auth-code-end-point"}},[_vm._v("Auth code endpoint")]),(
                  !_vm.externalLinkAccountDetails.tenantAuthCodeEndPoint &&
                  _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Auth code endpoint required.")]):_vm._e(),(
                  _vm.externalLinkAccountDetails.tenantAuthCodeEndPoint &&
                  _vm.formSubmitted &&
                  !_vm.validateUrl(
                    _vm.externalLinkAccountDetails.tenantAuthCodeEndPoint
                  )
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Invalid url.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"12","lg":"10"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                  'is-invalid':
                    (!_vm.externalLinkAccountDetails.tenantAccessTokenEndPoint &&
                      _vm.formSubmitted) ||
                    (!_vm.validateUrl(
                      _vm.externalLinkAccountDetails.tenantAccessTokenEndPoint
                    ) &&
                      _vm.formSubmitted),
                },attrs:{"id":"access-token-end-point","type":"text","placeholder":" "},model:{value:(_vm.externalLinkAccountDetails.tenantAccessTokenEndPoint),callback:function ($$v) {_vm.$set(_vm.externalLinkAccountDetails, "tenantAccessTokenEndPoint", $$v)},expression:"externalLinkAccountDetails.tenantAccessTokenEndPoint"}}),_c('label',{attrs:{"for":"access-token-end-point"}},[_vm._v("Access token endpoint")]),(
                  !_vm.externalLinkAccountDetails.tenantAccessTokenEndPoint &&
                  _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Access token endpoint required.")]):_vm._e(),(
                  _vm.externalLinkAccountDetails.tenantAccessTokenEndPoint &&
                  _vm.formSubmitted &&
                  !_vm.validateUrl(
                    _vm.externalLinkAccountDetails.tenantAccessTokenEndPoint
                  )
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Invalid url.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"12","lg":"10"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                  'is-invalid':
                    !_vm.externalLinkAccountDetails.clientId && _vm.formSubmitted,
                },attrs:{"id":"client-id","type":"text","placeholder":" "},model:{value:(_vm.externalLinkAccountDetails.clientId),callback:function ($$v) {_vm.$set(_vm.externalLinkAccountDetails, "clientId", $$v)},expression:"externalLinkAccountDetails.clientId"}}),_c('label',{attrs:{"for":"client-id"}},[_vm._v("Client ID")]),(!_vm.externalLinkAccountDetails.clientId && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Client ID required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"12","lg":"10"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                  'is-invalid':
                    !_vm.externalLinkAccountDetails.clientSecret && _vm.formSubmitted,
                },attrs:{"id":"client-secret","type":"text","placeholder":" "},model:{value:(_vm.externalLinkAccountDetails.clientSecret),callback:function ($$v) {_vm.$set(_vm.externalLinkAccountDetails, "clientSecret", $$v)},expression:"externalLinkAccountDetails.clientSecret"}}),_c('label',{attrs:{"for":"client-secret"}},[_vm._v("Client secret")]),(
                  !_vm.externalLinkAccountDetails.clientSecret && _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Client secret required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"12","lg":"10"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                  'is-invalid':
                    !_vm.externalLinkAccountDetails.policy && _vm.formSubmitted,
                },attrs:{"id":"policy","type":"text","placeholder":" "},model:{value:(_vm.externalLinkAccountDetails.policy),callback:function ($$v) {_vm.$set(_vm.externalLinkAccountDetails, "policy", $$v)},expression:"externalLinkAccountDetails.policy"}}),_c('label',{attrs:{"for":"policy"}},[_vm._v("Policy")]),(!_vm.externalLinkAccountDetails.policy && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Policy required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"12","lg":"10"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                  'is-invalid':
                    !_vm.externalLinkAccountDetails.scope && _vm.formSubmitted,
                },attrs:{"id":"scope","type":"text","placeholder":" "},model:{value:(_vm.externalLinkAccountDetails.scope),callback:function ($$v) {_vm.$set(_vm.externalLinkAccountDetails, "scope", $$v)},expression:"externalLinkAccountDetails.scope"}}),_c('label',{attrs:{"for":"scope"}},[_vm._v("Scope")]),(!_vm.externalLinkAccountDetails.scope && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Scope required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"12","lg":"10"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                  'is-invalid':
                    !_vm.externalLinkAccountDetails.grantType && _vm.formSubmitted,
                },attrs:{"id":"grant-type","type":"text","placeholder":" "},model:{value:(_vm.externalLinkAccountDetails.grantType),callback:function ($$v) {_vm.$set(_vm.externalLinkAccountDetails, "grantType", $$v)},expression:"externalLinkAccountDetails.grantType"}}),_c('label',{attrs:{"for":"grant-type"}},[_vm._v("Grant type")]),(!_vm.externalLinkAccountDetails.grantType && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Grant type required.")]):_vm._e()],1)])],1)],1)],1):_vm._e()]}},{key:"footer",fn:function({}){return [_c('b-button',{directives:[{name:"activeBlur",rawName:"v-activeBlur"}],staticClass:"btn-h-44",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.onResetExternalLinkAccount}},[_vm._v("Reset")]),_c('b-button',{directives:[{name:"activeBlur",rawName:"v-activeBlur"}],staticClass:"ml-3 btn-h-44",attrs:{"type":"button","disabled":_vm.saveLoadingIcon,"variant":"primary"},on:{"click":_vm.onSubmitExternalLinkAccount}},[_vm._v("Save"),(_vm.saveLoadingIcon)?_c('b-spinner',{staticClass:"ml-2",attrs:{"label":"Spinning","small":""}}):_vm._e()],1)]}}]),model:{value:(_vm.showExternalLinkAccountSideBar),callback:function ($$v) {_vm.showExternalLinkAccountSideBar=$$v},expression:"showExternalLinkAccountSideBar"}})
}
var staticRenderFns = []

export { render, staticRenderFns }